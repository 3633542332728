var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Dashboard Ambtelijk")
            ])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _c("DateFilter", {
                    on: { change: _vm.fetchStatisticsKpi },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel--spaced" },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Contract KPI's Ambtelijk")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm.statisticsKpi
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "", "text-xs-center": "" } },
                                _vm._l(
                                  Object.keys(_vm.statisticsKpi.ambtelijk),
                                  function(key, index) {
                                    return _c(
                                      "v-flex",
                                      {
                                        key: "total-" + key + "-" + index,
                                        attrs: { xs6: "", md3: "" }
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/rapportage/opgeleverd-matrix?filter=ambtelijk_" +
                                                key +
                                                "&from=" +
                                                _vm.from +
                                                "&till=" +
                                                _vm.till
                                            }
                                          },
                                          [
                                            _c("StatisticsNumberWidget", {
                                              attrs: {
                                                item: {
                                                  class: [
                                                    _vm.getKpiClass(
                                                      _vm.statisticsKpi
                                                        .ambtelijk[key],
                                                      89,
                                                      85,
                                                      true
                                                    )
                                                  ],
                                                  icon: "zoom_in",
                                                  amount:
                                                    _vm.statisticsKpi.ambtelijk[
                                                      key
                                                    ] + "%",
                                                  description:
                                                    _vm.getKpiLabel[key]
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel--spaced" },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Huidige werkvoorraad Ambtelijk")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm.statisticsTotals
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "", "text-xs-center": "" } },
                                _vm._l(
                                  Object.keys(_vm.statisticsTotals.ambtelijk),
                                  function(key, index) {
                                    return _c(
                                      "v-flex",
                                      {
                                        key: "total-" + key + "-" + index,
                                        attrs: { xs6: "", md4: "" }
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/reports?status=" +
                                                key +
                                                "&filter=ambtelijk"
                                            }
                                          },
                                          [
                                            _c("StatisticsNumberWidget", {
                                              attrs: {
                                                item: {
                                                  class: [
                                                    _vm.getKpiClass(
                                                      _vm.statisticsTotals
                                                        .ambtelijk[key],
                                                      93,
                                                      90,
                                                      true
                                                    )
                                                  ],
                                                  amount:
                                                    _vm.statisticsTotals
                                                      .ambtelijk[key],
                                                  icon: "zoom_in",
                                                  description: _vm.getStatusLabel(
                                                    key
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }