import { getKpiClass } from '@/support/Dashboard';
import { DateTime } from 'luxon';
import { getStatusLabel } from '@/support/ReportStatus';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { ReportTypes } from '@/support/ReportTypes';

@Component<AmbtelijkDashboard>({})
export default class AmbtelijkDashboard extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */
  public $pageTitle = 'AmbtelijkDashboard';

  protected isLoading = true;

  protected statisticsTotals = null;

  protected statisticsKpi = null;

  protected date = '';

  protected from = '';

  protected till = '';

  protected reportTypes = ReportTypes;
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected mounted() {
    this.date = DateTime.local().toFormat('yyyy-LL-dd');
    this.initBreadcrumb();
    this.initialize();
  }

  // #endregion

  // #region Class methods
  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'AmbtelijkDashboard' },
        ],
      });
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;
    await this.fetchStatisticsTotals();
    await this.fetchStatisticsKpi();
    this.isLoading = false;
  }

  protected getStatusLabel(key: string): string {
    return getStatusLabel(key);
  }

  protected getKpiClass(amount: number, warningAt = 5, alertAt = 10, inverted = false) {
    return getKpiClass(amount, warningAt, alertAt, inverted);
  }

  protected getKpiLabel: { [key: string]: string } = {
    doorlooptijd_opname: 'Met opname',
    doorlooptijd_zonder_opname: 'Zonder opname',
    doorlooptijd_hoorzitting: 'Hoorzitting',
    doorlooptijd_calculatie: 'Calculatie',
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async fetchStatisticsTotals() {
    const payload = {
      signature: 'management-reports:bezwaar-overview',
      body: {},
    };

    const response = await new Rpc()
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response.data) {
      this.statisticsTotals = response.data.totals;
    }
  }

  protected async fetchStatisticsKpi() {
    const payload = {
      signature: 'management-reports:bureau-bezwaar-dashboard',
      body: {
        date: this.date,
      },
    };

    const response = await new Rpc()
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response.data) {
      this.statisticsKpi = response.data.contract_score_this_month;
      this.from = response.data.from;
      this.till = response.data.till;
    }
  }

  // #endregion

  // #region Getters & Setters

  // #endregion

  // #region @Watchers

  // #endregion
}
